/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:1892aa55-6907-414a-9964-7935bc4ecebb",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_V3kU0qvWl",
    "aws_user_pools_web_client_id": "qdci19dv4tjbqkbivsm2v75d6",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://laqck26e00.execute-api.ca-central-1.amazonaws.com/qwppedev",
            "region": "ca-central-1"
        },
        {
            "name": "saveledgerentry",
            "endpoint": "https://62s533ms77.execute-api.ca-central-1.amazonaws.com/qwppedev",
            "region": "ca-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ppetable-qwppedev",
            "region": "ca-central-1"
        },
        {
            "tableName": "locationinfo-qwppedev",
            "region": "ca-central-1"
        },
        {
            "tableName": "ppeledger-qwppedev",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "qwantifyppecartprofile141516-qwppedev",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "qwppedev-qwppedev",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d1ebjnio8dgp2a.cloudfront.net"
};


export default awsmobile;
